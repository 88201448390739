<template>
    <v-container fluid>
        <v-row row wrap>
            
            <v-col cols="12"  mt-1>
                <v-tabs grow>

                    <v-tab >Send New Message</v-tab>
                    <v-tab-item>
                        <send-message/>
                    </v-tab-item>
                    
                    <v-tab>Message History</v-tab>
                    <v-tab-item>
                       <message-history/>
                    </v-tab-item>

                </v-tabs>

            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations} from 'vuex'
import axios from 'axios'
import api from '@/store/api'
import AllMixins from '@/mixins/AllMixins'
import moment from 'moment'
import SendMessage from './SendMessage.vue'
import MessageHistory from './MessageHistory.vue'
export default {
    components: { SendMessage, MessageHistory },
    mixins: [AllMixins],
    data() {
        return {
            active: null,
            sentToAdmins: false,
            customSubject: '',
            customMessage: '',
            selected: null,
            personalized: false,
            link: true,
            sendToActiveMembers: true,
            sendToFutureMembers: false
        }
    },
    mounted () {
        this.getAvailableProducts()
    },
    computed: {
        ...mapGetters ([
    //        'loading'
        ]),
        products () {
            let products = this.$store.state.products
            if(products){
                return products.filter(product => product.status == "Approved") //state.products includes draft products for admins to test before release; only allow messaging about approved products
            }
            else{
                return []
            }
        },
        customizedMessage () {
            let msg = this.customMessage
            if(this.link){
                msg += "\n\n"
                msg += "Login to your account at https://saguaro.botls.app"
            }
            return msg
        },
        productReleaseSubject () {
            if(!this.selected)
                return "Select a product..."
            else
                return "Now Available for Pre-Order: " + this.selected.name.toUpperCase()
        },
        productReleaseMessage () {
            let body = {}
            body.html = "Select a product..."
            if(!this.selected)
                return body
            body.html = "Introducing <strong>" + this.selected.name.toUpperCase() + "</strong>, a " + this.selected.abv + "% " + this.selected.style + "...<br><br>"
            body.html += this.selected.description + "<br><br>"
           // body.html += "<img src=\"" + this.selected.art.thumbnails.large.url + "\" width=\"300\" alt=\"" + this.selected.name + "\"><br><br>"
            body.html += "<img src=\"" + this.selected.imageLarge + "\" width=\"300\" alt=\"" + this.selected.name + "\"><br><br>"
            body.html += "$" + this.selected.price + " per " + this.selected.size + ", limit " + this.selected.limit + " per person <br><br>"
            body.html += "<strong>PRE-ORDER DEADLINE</strong>: " + this.formatDate(this.selected.orderEnd) + "<br><br>"
            body.html += "<strong>PICKUP WINDOW</strong>: " + this.formatDate(this.selected.pickupStart) + " until " + this.formatDate(this.selected.pickupEnd) + "<br><br>"
            body.html += "Please <a href=\"https://saguaro.botls.app\">login to your account</a> to place a pre-order"

            body.text = "Introducing " + this.selected.name.toUpperCase() + ", a " + this.selected.abv + "% " + this.selected.style + "...\n\n"
            body.text += this.selected.description + "\n\n"
            body.text += "$" + this.selected.price + " per " + this.selected.size + ", limit " + this.selected.limit + " per person \n\n"
            body.text += "PRE-ORDER DEADLINE: " + this.formatDate(this.selected.orderEnd) + "\n\n"
            body.text += "PICKUP WINDOW: " + this.formatDate(this.selected.pickupStart) + " until " + this.formatDate(this.selected.pickupEnd) + "\n\n"
            body.text += "Please login to your account to place a pre-order at https://saguaro.botls.app"

            return body
        },
        greeting () {
            if(this.personalized)
                return "Hi [member name],"
            else
                return "Greetings Members,"
        }
    },
    methods: {
        ...mapMutations ([
     //       'setLoading'
        ]),
        formatDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY ha")
        },
        loadProductMessage () {

        },
        reset () {
            this.active = 0
            this.sentToAdmins = false
            this.customSubject =  ''
            this.customMessage = ''
            this.selected = null
        }
    },
}
</script>
